<template>
  <div class="wrap">
    <div v-loading="loading" class="detail">
      <div class="head">
        <div class="left">
          <div class="top">
            <span class="top-left">
              <span class="top-title">子服务ID号：{{ data.serviceItemNo }}</span>
              <span>● {{ data.stateName }}</span>
            </span>
            <span v-if="data.state == 7" class="reason"
              >（拒单原因：{{ data.reason || '--' }}）</span
            >
          </div>
          <div v-if="data.state == 5" class="desc">
            服务评分：
            <el-rate v-model="data.serviceItemScore" disabled></el-rate>
          </div>
          <div v-if="data.state == 5" class="desc">
            换算系数：{{
              (data.conversionFactor && Number(data.conversionFactor).toFixed(4)) || '--'
            }}
          </div>
          <div
            v-if="
              data.baseProductType === 1 ||
              data.baseProductType === 2 ||
              data.baseProductType === 3 ||
              data.baseProductType === 4 ||
              data.baseProductType === 15
            "
            class="desc"
          >
            服务时间：{{ data.serviceDto.appointmentShootDatetime }}
          </div>
          <div v-if="data.baseProductType === 8 && !!data.entityInfo" class="desc">
            服务内容：{{ JSON.parse(data.entityInfo).productName }} | {{ data.baseProductName }} |
            {{ getBaseProductTypeName(data.baseProductType) }} | 1
            {{ JSON.parse(data.entityInfo).priceUnit }} | 1 {{ data.priceUnit }}
          </div>
          <div v-else class="desc">
            服务内容：{{ data.baseProductName || '--' }} |
            {{ getBaseProductTypeName(data.baseProductType) }} | {{ data.number + data.priceUnit }}
          </div>
          <div
            v-if="
              data.baseProductType === 1 ||
              data.baseProductType === 2 ||
              data.baseProductType === 3 ||
              data.baseProductType === 4 ||
              data.baseProductType === 15
            "
            class="desc"
          >
            服务地址：{{ data.serviceDto.appointmentStroeAddress || '--' }}
          </div>
          <div class="desc">
            预约信息：{{ data.contactPersonName || '--' }}&emsp;{{
              data.contactPersonPhone || '--'
            }}
          </div>
          <div class="desc">
            <span>关联服务订单号：{{ data.serviceNo }}</span>
            <span>创建时间：{{ data.createTime || '--' }}</span>
            <span>派单时间：{{ data.dispatchTime || '--' }}</span>
          </div>
        </div>

        <div class="right">
          <Btns
            query-type="queryType"
            type="list"
            :item="data"
            style="margin-right: 8px"
            @refresh="refresh"
          />
          <el-button
            v-if="handleShowRegBtn"
            :class="data.serviceDto.shootStatus > '0' ? 'btn-reg' : 'btn-no'"
            size="small"
            type="primary"
            @click="goShootReg"
            >出棚登记</el-button
          >
          <!-- <el-button v-if="data.baseServiceType == 14 && (data.state == 3 || data.state == 4 || data.state == 5)" @click="downLoad(item, 14)">下载待剪辑视频</el-button>
          <el-button v-if="(data.baseServiceType == 7 || data.baseServiceType == 8) && (data.state == 3 || data.state == 4 || data.state == 5)" @click="downLoad(item, 1)">下载原片</el-button>
          <el-button v-if="(data.baseServiceType == 5 || data.baseServiceType == 6) && (data.state == 3 || data.state == 4 || data.state == 5)" @click="downLoad(item, 5)">下载待修照片</el-button>
          <el-button v-if="(data.baseServiceType == 7 || data.baseServiceType == 8) && (data.state == 3 || data.state == 4 || data.state == 5)" @click="downLoad(item, 5)">下载初修照片</el-button>
          <el-button v-if="(data.baseServiceType == 7 || data.baseServiceType == 8) && (data.state == 3 || data.state == 4 || data.state == 5)" @click="downLoad(item, 6)">下载精修照片</el-button>
          <el-button v-if="data.baseServiceType == 7 && (data.state == 3 || data.state == 4 || data.state == 5)" @click="downLoad(item, 7)">下载已选版面</el-button>
          <el-button v-if="data.baseServiceType == 8 && (data.state == 3 || data.state == 4 || data.state == 5)" @click="downLoad(item, 7)">下载设计版面</el-button> -->
        </div>
      </div>
      <div v-loading="boxLoading" class="tab-content">
        <ul class="flex tab-list">
          <li
            v-for="(item, i) in tabList"
            :key="i"
            class="tab-list-item"
            :class="item.checked ? 'item-checked' : ''"
            @click="handleTab(item, item.id)"
          >
            {{
              item.name ||
              (item.id === 5 ? '待' : '') +
                (type === 7 && item.id === 2 ? '设计版面照片' : getName(type))
            }}
            <span v-if="item.num">({{ item.num }})</span>
          </li>
        </ul>
        <div class="tab-data">
          <keep-alive>
            <record v-if="checkedId == 1" :current-data="data" />
          </keep-alive>
          <notChangedPhoto v-if="checkedId == 5" :name="getName2(type)" :current-data="data" />
          <photos v-if="checkedId == 2" ref="photos" :name="getName2(type)" :current-data="data" />
          <Videos
            v-if="checkedId == 4"
            ref="Videos"
            :name="getName2(type)"
            :type="type"
            :current-data="data"
          />
          <evaluate v-if="checkedId == 3" :current-data="data" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Btns from './components/btns.vue'
import record from './components/record.vue'
import evaluate from './components/evaluate.vue'
import photos from './components/photos.vue'
import Videos from './components/videos.vue'
import notChangedPhoto from './components/notChangedPhoto.vue'
import { serviceitemsDetail, getDetailPhotos } from '@/api/order'
import { getBaseProductTypeName } from './components/common'

export default {
  components: { Btns, record, evaluate, photos, Videos, notChangedPhoto },
  data() {
    return {
      data: { serviceDto: {} },
      value1: 5,
      tabList: [],
      list: [
        { name: '服务记录', id: 1, num: 0, checked: true },
        { name: '', id: 5, num: 0, checked: false }, // 待...
        { name: '', id: 2, num: 0, checked: false }, // 照片
        { name: '', id: 4, num: 0, checked: false }, // 视频
        { name: '服务评价', id: 3, num: 0, checked: false },
      ],
      checkedId: 1,
      boxLoading: false,
      loading: false,
      type: '',
      downLoading: false,
      name: '',
    }
  },
  //  watch: {
  //   $route: {
  //     immediate: true,
  //     handler: async function () {
  //       this.getTableList();
  //     },
  //   },
  // },

  computed: {
    getBaseProductTypeName,
    getName() {
      return (type) => {
        switch (type) {
          case 1:
            return '拍摄原片'
          case 5:
            return '初修照片'
          case 6:
            return '精修照片'
          case 7:
            return '设计素材'
          case 8:
            return '制作素材'
          case 15:
            return '拍摄原视频'
          case 14:
            return '剪辑视频'
          default:
            break
        }
      }
    },
    getName2() {
      return (type) => {
        switch (type) {
          case 1:
            return '原片'
          case 5:
            return '初修照片'
          case 6:
            return '精修照片'
          case 7:
            return '设计版面照片'
          case 8:
            return '制作素材'
          case 15:
            return '原视频'
          case 14:
            return '剪辑视频'
          default:
            break
        }
      }
    },

    handleShowRegBtn() {
      if (
        this.data.baseServiceType === 1 &&
        (this.data.state === 4 ||
          this.data.state === 5 ||
          this.data.state === 7 ||
          this.data.state === 9 ||
          this.data.state === 11)
      )
        return true
      if (
        this.data.baseServiceType === 6 &&
        (this.data.state === 0 ||
          this.data.state === 3 ||
          this.data.state === 4 ||
          this.data.state === 7 ||
          this.data.state === 5 ||
          this.data.state === 11)
      ) {
        return true
      }
      return false
    },
  },
  mounted() {
    this.getTableList()
  },
  methods: {
    goShootReg() {
      this.$router.push({
        name: 'shootRegister',
        query: {
          serviceId: this.data.serviceId,
          storeId: this.data.storeId,
          shootStatus: this.data.serviceDto.shootStatus,
        },
      })
    },
    filterList(type) {
      this.type = type
      this.tabList = []

      let ids = [1, 2]

      if (type === 1) {
        ids = [1, 2, 3]
      }
      if (type === 15) {
        ids = [1, 4, 3]
      }
      if (type === 2 || type === 3 || type === 4) {
        ids = [1, 3]
      }
      if (type === 5 || type === 6 || type === 7 || type === 8) {
        ids = [1, 5, 2, 3]
      }
      if (type === 8) {
        ids = [1, 5, 3]
      }
      if (type === 14) {
        ids = [1, 5, 4, 3]
      }
      this.list.forEach((e) => {
        ids.forEach((v) => {
          if (e.id === v) {
            this.tabList.push(e)
          }
        })
      })
      this.$forceUpdate()
    },
    refresh() {
      this.getTableList()
      if (this.checkedId === 2) {
        this.$refs.photos.getDetailPhotos()
      }
      if (this.checkedId === 4) {
        this.$refs.Videos.getDetailPhotos()
      }
    },
    async downLoad(row, e) {
      let data = []
      this.downLoading = true
      try {
        data =
          (await getDetailPhotos({
            data: { serviceItemId: row.serviceItemId, baseProductType: e },
            pageNumber: 1,
            pageSize: 9999,
          }).data.content) || []
        data = data.map((e, i) => {
          return {
            url: e.url.split('?')[0],
            name: i + 1,
            suffix: e.url.substring(e.url.lastIndexOf('.')),
          }
        })
        if (data.length > 0) {
          await this.$downloadPhoto(data)
        }
        this.downLoading = false
      } catch (error) {
        this.downLoading = false
      }
    },

    getTableList() {
      this.loading = true
      serviceitemsDetail({
        data: this.$route.query.serviceItemId,
      })
        .then((res) => {
          this.loading = false
          res.data.serviceItemScore = Number(res.data.serviceItemScore)
          this.data = res.data
          this.filterList(this.data.baseProductType)
        })
        .catch(() => {
          this.loading = false
        })
    },
    back() {
      this.$emit('back')
    },
    handleTab(item, i) {
      const currenObj = this.tabList.find((e) => e.checked)
      currenObj.checked = false
      item.checked = !item.checked
      this.checkedId = i
    },
  },
}
</script>

<style lang="less" scoped>
.detail {
  .flex {
    align-items: center;
  }

  .head {
    background-color: #fff;
    padding: 16px 20px;
    display: flex;
    justify-content: space-between;

    .right {
      // min-width: 340px;
      display: flex;
      justify-content: flex-end;

      .el-button {
        margin-left: 0;
        height: 32px;
        margin-right: 10px;
        margin-bottom: 10px;
      }

      .btn-no {
        color: #ffffff;
        background-color: #2861e2;
        border-color: #2861e2;
      }
      .btn-reg {
        background-color: #e9effc;
        color: #2861e2;
        border: 1px solid #e9effc;
      }
    }

    .top {
      font-size: 14px;
      color: #666;
      display: flex;
      justify-content: space-between;

      .top-left {
        white-space: nowrap;
      }

      .reason {
        padding-top: 4px;
        white-space: normal;
        word-break: break-all;
        color: #999;
        font-size: 14px;
      }

      .top-title {
        font-size: 18px;
        font-weight: bold;
        color: #333;
        padding-right: 20px;
      }
    }

    .desc {
      padding: 8px;
      color: #999;
      font-size: 12px;
      display: flex;
      align-items: center;
    }

    .desc > span {
      padding-right: 20px;
    }
  }

  .tab-content {
    margin-top: 16px;
    background-color: #fff;

    .tab-data {
      padding: 40px;
      padding-bottom: 20px;

      .el-timeline-item__node--normal {
        width: 6px;
        height: 6px;
        left: 0px;
      }

      .el-timeline-item__node {
        border: 2px solid #0079fe;
        background-color: #fff;
      }

      .el-timeline-item__wrapper {
        display: flex;
        align-items: center;

        .el-timeline-item__content {
          color: #7f7f7f;
          font-size: 16px;
        }

        .el-timeline-item__timestamp {
          font-size: 16px;
          margin-top: 0;
          color: #7f7f7f;
          padding-left: 20px;
        }
      }

      .evaluate {
        font-size: 12px;

        .name {
          font-weight: bold;
          display: flex;
          align-items: center;

          .line {
            display: inline-block;
            width: 5px;
            height: 20px;
            background-color: #0079fe;
            margin-right: 10px;
          }
        }

        .content {
          padding-top: 16px;

          .box {
            border: 1px solid #eee;
            padding: 20px;
          }

          .reply {
            background-color: #f7fbfe;
            padding: 10px;
          }
        }
      }
    }

    .tab-list {
      font-size: 14px;
      color: #9a9d9e;
      border-bottom: 1px solid #ededed;

      .tab-list-item {
        margin-left: 30px;
        padding: 16px 0;

        &:hover {
          cursor: pointer;
          color: #000000;
          border-bottom: 3px solid #2861e2;
        }
      }

      .item-checked {
        color: #000000;
        border-bottom: 3px solid #2861e2;
      }
    }
  }
}
</style>
