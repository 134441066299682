<template>
  <div>
    <div v-if="data.evaluationId" class="evaluate">
      <div class="flex">
        <div class="name">
          <span class="line"></span>
          <span>本次{{ data.scoreItemName }}评分</span>
        </div>
        <el-rate v-model="data.score" style="margin-left: 20px" disabled></el-rate>
        <span style="margin-left: 40px">评价时间：{{ data.createTime }}</span>
      </div>

      <div style="margin-top: 20px" class="name">
        <span class="line"></span>
        <span>客户整体服务评价</span>
      </div>
      <div class="content">
        <div class="box">
          <div class="text">{{ data.evaluation }}</div>
          <el-image
            v-for="(item, i) in data.evaluationPictureUrl"
            :key="i"
            lazy
            class="img"
            fit="cover"
            :src="showUrl(item, `x-oss-process=image/resize,m_fixed,h_${imageSize},w_${imageSize}`)"
            :preview-src-list="getImgList(i)"
          >
            <span slot="error" class="el-image-error">
              <el-image class="img" :src="item" :preview-src-list="getImgList(i)" lazy>
                <span slot="error" class="el-image-error">暂无图片</span>
              </el-image>
            </span>
          </el-image>
        </div>
        <div v-if="data.evaluationReply" class="reply">回复：{{ data.evaluationReply }}</div>
      </div>
    </div>
    <el-empty v-else :image-size="200"></el-empty>
  </div>
</template>

<script>
import { getOrderItemEvaluation } from '@/api/order'
import { showUrl } from '@/utils/yaImg'

export default {
  props: {
    currentData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      value1: 3,
      data: { evaluationPictureUrl: [] },
      imageSize: 200,
      previews: [],
    }
  },
  mounted() {
    this.getOrderItemEvaluation()
  },
  methods: {
    showUrl,
    getImgList(index) {
      return this.previews.slice(index).concat(this.previews.slice(0, index))
    },
    getOrderItemEvaluation() {
      getOrderItemEvaluation({
        data: {
          serviceId: this.currentData.serviceId,
          scoreItemType: this.currentData.baseProductType,
        },
      }).then((res) => {
        res.data.evaluationPictureUrl = JSON.parse(res.data.evaluationPictureUrl)
        this.previews = res.data.evaluationPictureUrl
        this.data = res.data
      })
    },
  },
}
</script>

<style lang="less" scoped>
.evaluate {
  font-size: 13px;
  .img {
    width: 100px;
    height: 90px;
    margin-top: 20px;
    margin-right: 20px;
  }
  .line {
    display: inline-block;
    width: 5px;
    height: 20px;
    background-color: #0079fe;
    margin-right: 4px;
  }
  .flex {
    align-items: center;
  }
  .name {
    display: flex;
    align-items: center;
  }
  .content {
    border: 1px solid #eee;
    margin-top: 10px;
    .box {
      padding: 16px;
    }
    .reply {
      background-color: #f7fbfe;
      padding: 10px;
    }
  }
}
</style>
