<template>
  <div>
    <el-timeline v-if="list.length > 0">
      <el-timeline-item v-for="(n, index) in list" :key="index" color="#0079fe">
        <div class="record">
          <div class="left">
            {{ n.createTime }}
          </div>
          <div class="right">
            <div>{{ n.remark }}</div>
            <div>{{ n.createUserName }}&ensp;{{ n.phoneNumber || '--' }}</div>
            <div v-if="!!n.reson">拒绝原因：{{ n.reson }}</div>
          </div>
        </div>
      </el-timeline-item>
    </el-timeline>
    <el-empty v-else :image-size="200"></el-empty>
  </div>
</template>

<script>
import { getLogList } from '@/api/order'
export default {
  props: {
    currentData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      list: [],
    }
  },
  watch: {
    'currentData.serviceItemId': {
      deep: true,
      handler() {
        this.getLogList()
      },
    },
  },
  mounted() {},
  methods: {
    getLogList() {
      getLogList({
        data: this.currentData.serviceItemId,
      }).then((res) => {
        this.list = res.data
      })
    },
  },
}
</script>

<style lang="less" scoped>
.record {
  display: flex;
  color: #999;
  line-height: 20px;
  .left {
    white-space: nowrap;
  }
  .right {
    margin-left: 60px;
  }
}
</style>
