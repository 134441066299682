<template>
  <div>
    <div v-for="(item, i) in list" :key="i">
      <div v-if="type == 7 || type == 8" class="name">
        <span class="line"></span>
        {{ item.name }}
      </div>
      <div class="btn">
        <div>
          <el-button :disabled="item.imgs.length == 0" :loading="item.downLoading" size="medium" @click="downloadPhoto(item)">
            <span v-if="downLoading">下载中...</span>
            <span v-else>
              {{ type == 7 || type == 8 ? '下载' + (item.baseProductType == 8 ? '制作相关照片' : item.name) : '下载待' + name }}
            </span>
          </el-button>
          <span style="font-size: 14px; color: #999; margin-left: 20px">* 点击图片可切换查看大图</span>
        </div>
      </div>
      <div v-if="item.baseProductType == 8 && item.imgs.length > 0" class="productName">
        {{ productObj.productParentName }} | {{ productObj.parentProductSpecification || '--' }} | {{ productObj.productName }} | {{ productObj.parentDesignNum }}P
      </div>
      <div v-if="item.baseProductType == 8 && item.imgs[0] && item.imgs[0].remark" class="remark">
        {{ item.imgs[0].remark }}
      </div>
      <el-scrollbar v-if="item.imgs.length > 0" v-loading="loading" class="scrollBox" :style="{ height: type == 7 || type == 8 ? '380px' : '500px' }">
        <div v-for="(n, v) in item.imgs" :key="v" class="imgs">
          <el-image
            v-if="n.fileType == 1"
            class="img"
            fit="cover"
            :src="showUrl(n.url, `x-oss-process=image/resize,m_fixed,h_${imageSize},w_${imageSize}`)"
            :preview-src-list="getImgList(item.imgs, v)"
          >
            <span slot="error" class="el-image-error">
              <el-image class="img" :src="n.url" lazy>
                <span slot="error" class="el-image-error">
                  <el-image class="img" :src="n.url" :preview-src-list="getImgList(item.imgs, v)" lazy>
                    <span slot="error" class="el-image-error">加载失败</span>
                  </el-image>
                </span>
              </el-image>
            </span>
          </el-image>
          <div v-else class="video">
            <el-image class="img" :src="n.coverUrl" fit="cover">
              <span slot="error">
                <span slot="error" class="el-image-error">加载失败</span>
              </span>
            </el-image>
            <div class="play" @click="toPlayVideo(n)">
              <div class="icon"></div>
            </div>
          </div>
          <div v-if="n.remark && (n.baseProductType == 7 || n.baseProductType == 6)" class="desc">
            {{ n.remark }}
          </div>
        </div>
      </el-scrollbar>
      <el-empty v-else :image-size="type == 7 || type == 8 ? 100 : 200"></el-empty>
    </div>
    <my-video :visible="videoVisible" :url="videoUrl" @cancel="videoVisible = false" />
  </div>
</template>

<script>
import { getDetailPhotos, getSelectParentDetail, getSelectListByConditionPhotos } from '@/api/order'
import { showUrl } from '@/utils/yaImg'
import myVideo from '@/components/video/video.vue'

export default {
  components: { myVideo },
  props: {
    name: {
      type: String,
      default: '',
    },
    currentData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      videoVisible: false,
      videoUrl: '',
      imgList: [],
      loading: false,
      downLoading: false,
      imageSize: 200,
      dialogVisible: false,
      desc: '',
      imgData: [],
      previews: [],
      flagList: [
        { name: '已选制作内容', baseProductType: 8, imgs: [], downLoading: false },
        { name: '已选版面', baseProductType: 7, imgs: [], downLoading: false },
        { name: '精修照片', baseProductType: 6, imgs: [], downLoading: false },
        { name: '初修照片', baseProductType: 5, imgs: [], downLoading: false },
        { name: '原片', baseProductType: 1, imgs: [], downLoading: false },
      ],
      productObj: {},
      list: [],
      type: '',
    }
  },
  mounted() {
    this.type = this.currentData.baseProductType
    console.log(this.type)
    if (this.type === 7) {
      this.list = this.flagList.slice(1)
    } else if (this.type === 8) {
      this.flagList[1].name = '设计版面'
      this.list = this.flagList
      getSelectParentDetail({
        data: {
          orderProductId: this.currentData.orderProductId,
          batchNum: this.currentData.batchNum,
        },
      }).then((res) => {
        this.productObj = res.data
      })
    } else {
      this.list = [{ imgs: [] }]
    }

    if (this.type !== 6) this.getDetailPhotos()
    if (this.type === 6 || this.type === 7 || this.type === 8) this.getSelectListByConditionPhotos()
  },
  methods: {
    showUrl,
    getImgList(imgs, index) {
      const list = imgs.map((e) => e.url)
      return list.slice(index).concat(list.slice(0, index))
    },
    getSelectListByConditionPhotos() {
      const { serviceId, baseProductType, orderProductId, batchNum } = this.currentData
      const type2Type = {
        1: 1,
        5: 1,
        6: 5,
        7: 7,
        8: 8,
      }
      const data = {
        serviceId,
        baseProductType: type2Type[baseProductType],
        orderProductId,
        batchNum,
      }
      getSelectListByConditionPhotos({ data }).then((res) => {
        console.log(this.list)
        this.list[0].imgs = res.data
        console.log(this.list)
      })
    },
    getDetailPhotos() {
      const type = this.currentData.baseProductType === 5 ? 1 : this.currentData.baseProductType === 14 ? 15 : this.currentData.baseProductType
      getDetailPhotos({
        pageNumber: 1,
        pageSize: 9999,
        data: {
          serviceId: this.currentData.serviceId,
          baseProductType: this.currentData.baseProductType === 8 || this.currentData.baseProductType === 7 ? undefined : type,
        },
      }).then((res) => {
        if (this.currentData.baseProductType === 8 || this.currentData.baseProductType === 7) {
          res.data.content.forEach((e) => {
            this.list.forEach((v, i) => {
              if (e.baseProductType === v.baseProductType && i !== 0) {
                v.imgs.push(e)
              }
            })
          })
        } else {
          this.list[0].imgs = res.data.content
        }
        this.imgList = res.data.content
        this.previews = this.imgList.map((item) => {
          return item.url
        })
      })
    },

    handleClose() {
      this.dialogVisible = false
    },
    async downloadPhoto(item) {
      const data = []
      item.imgs.forEach((e, i) => {
        data.push({
          url: e.url.split('?')[0],
          name: i + 1,
          suffix: e.url.substring(e.url.lastIndexOf('.')),
        })
      })
      item.downLoading = true
      await this.$downloadPhoto(data)
      item.downLoading = false
    },
    toPlayVideo(file) {
      this.videoVisible = true
      this.videoUrl = file.url
    },
  },
}
</script>

<style lang="less" scoped>
@import '../../../styles/imgsList.less';
.remark {
  padding: 16px;
  background-color: #f4f4f4;
  margin-bottom: 10px;
}
.name {
  display: flex;
  align-items: center;
  padding: 20px 0;
}
.productName {
  padding: 10px;
  background-color: #00bfbf;
  font-size: 13px;
  color: #fff;
  margin-bottom: 10px;
  display: inline-block;
}
.line {
  display: inline-block;
  width: 4px;
  height: 20px;
  background-color: #0079fe;
  margin-right: 8px;
}
::v-deep .el-scrollbar__view {
  display: flex;
  flex-wrap: wrap;
  .imgs {
    position: relative;
    margin-right: 16px;
    margin-bottom: 12px;
    cursor: pointer;
    .desc {
      background-color: #f4f4f4;
      width: 176px;
      box-sizing: border-box;
      margin-top: 10px;
      color: #666;
      font-size: 14px;
      padding: 10px;
      word-break: break-all;
    }
    .img {
      border-radius: 10px;
      width: 176px;
      height: 160px;
    }
  }
}
</style>
