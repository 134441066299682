<template>
  <div>
    <div class="btn">
      <div>
        <el-button
          v-if="(currentData.state == 6 || currentData.state == 4 || currentData.state == 5) && (currentData.baseProductType == 15 || currentData.baseProductType == 14)"
          size="medium"
          type="primary"
          @click="upPhoto"
        >
          上传{{ name }}
        </el-button>
        <el-button :disabled="imgList.length == 0" :loading="downLoading" size="medium" @click="downloadPhoto">{{ downLoading ? '下载中...' : '下载' + name }}</el-button>
        <span style="font-size: 14px; color: #999; margin-left: 20px">* 点击可放大播放</span>
      </div>
      <div>
        <div v-if="isSelect">
          <el-checkbox v-model="checkAll" style="margin-right: 10px" @change="handleCheckAllChange">全选</el-checkbox>
          <el-button size="medium" type="primary" @click="del('批量')">批量删除</el-button>
          <el-button class="cancel" size="medium" type="primary" @click="isSelect = false">取消管理</el-button>
        </div>
        <el-button v-else size="medium" type="primary" @click="isSelect = true">管理视频</el-button>
      </div>
    </div>
    <div>
      <el-scrollbar v-if="imgList.length > 0" ref="elScroll" class="scrollBox" style="height: 500px">
        <el-checkbox-group v-model="checkedList" @change="handleCheckedCitiesChange">
          <div v-for="(n, i) in imgList" :key="i" class="imgs video">
            <el-image class="img" :src="n.coverUrl" fit="cover">
              <span slot="error">
                <span slot="error" class="el-image-error">加载失败</span>
              </span>
            </el-image>
            <div class="play" @click="toPlayVideo(n)">
              <div class="icon"></div>
            </div>
            <el-checkbox v-if="isSelect" :label="n.id"></el-checkbox>
            <el-button v-if="isSelect" size="mini" class="del" type="danger" icon="el-icon-delete" circle @click="del(n.id)"></el-button>
          </div>
        </el-checkbox-group>
      </el-scrollbar>
      <el-empty v-else :image-size="200"></el-empty>
      <Pages :current-page="form.pageNumber" :total="total" @handleSizeChange="handleSizeChange" @handleCurrentChange="handleCurrentChange" />
    </div>
    <UploadPhotoPopups v-if="isUploadPhoto" type="1" popups-title="上传照片" :show-state.sync="isUploadPhoto" :current-data="currentData" @hidePopups="isUploadPhoto = false" />
    <my-video :visible="videoVisible" :url="videoUrl" @cancel="videoVisible = false" />
  </div>
</template>

<script>
import UploadPhotoPopups from '@/views/orderMgt/orderList/components/uploadPhoto'
import myVideo from '@/components/video/video.vue'
import { getDetailPhotos, deleteBathUserPhoto } from '@/api/order'

export default {
  components: {
    UploadPhotoPopups,
    myVideo,
  },
  props: {
    type: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    currentData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      checkAll: false,
      isIndeterminate: true,
      checkedList: [],
      imgList: [],
      isUploadPhoto: false,
      isSelect: false,
      videoVisible: false,
      videoUrl: '',
      form: {
        pageSize: 9999,
        pageNumber: 1,
        data: {},
      },
      total: 0,
      downLoading: false,
    }
  },
  watch: {
    isSelect(val) {
      if (!val) {
        this.checkedList = []
        this.checkAll = false
      }
    },
  },
  mounted() {
    this.getDetailPhotos()
  },
  methods: {
    getDetailPhotos() {
      this.loading = true
      this.form.data.serviceItemId = this.currentData.serviceItemId
      this.form.data.baseProductType = this.currentData.baseProductType
      getDetailPhotos(this.form)
        .then((res) => {
          this.imgList = res.data.content
          this.total = Number(res.data.total)
          this.loading = false
          this.previews = this.imgList.map((item) => {
            return item.url
          })
        })
        .catch(() => {
          this.loading = false
        })
    },
    upPhoto() {
      this.isUploadPhoto = true
    },
    del(id) {
      let tip = '是否确定删除该照片'
      let ids = [id]
      if (id === '批量' && this.checkedList.length === 0) {
        this.$message({
          message: '请至少选择一张照片',
          type: 'warning',
        })
        return
      }
      if (id === '批量') {
        tip = `是否确定删除所选的${this.checkedList.length}照片`
        ids = this.checkedList
      }
      this.$confirm(tip, '确认提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        deleteBathUserPhoto({
          data: {
            ids: ids,
          },
        }).then(() => {
          this.$message({
            type: 'success',
            message: '删除成功!',
          })
          this.isSelect = false
          this.getDetailPhotos()
        })
      })
    },
    async downloadPhoto() {
      const data = []
      this.imgList.forEach((e, i) => {
        data.push({
          url: e.url.split('?')[0],
          name: i + 1,
          suffix: e.url.substring(e.url.lastIndexOf('.')),
        })
      })
      this.downLoading = true
      await this.$downloadPhoto(data)
      this.downLoading = false
    },
    handleCheckAllChange(val) {
      this.checkedList = val ? this.imgList.map((e) => e.id) : []
      this.isIndeterminate = false
    },
    handleCheckedCitiesChange(value) {
      const checkedCount = value.length
      this.checkAll = checkedCount === this.imgList.length
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.imgList.length
    },

    handleSizeChange(val) {
      this.form.pageSize = val
      this.form.pageNumber = 1
      this.getPhotoList()
    },
    handleCurrentChange(val) {
      this.form.pageNumber = val
      this.getPhotoList()
    },
    toPlayVideo(file) {
      this.videoVisible = true
      this.videoUrl = file.url
    },
  },
}
</script>

<style lang="less" scoped>
@import '../../../styles/imgsList.less';
</style>
