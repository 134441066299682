<template>
  <div>
    <div class="btn">
      <div>
        <el-button
          v-if="
            (currentData.state == 6 || currentData.state == 4 || currentData.state == 5) &&
            (currentData.baseProductType == 1 || currentData.baseProductType == 8 || currentData.baseProductType == 5 || currentData.baseProductType == 6)
          "
          size="medium"
          type="primary"
          @click="upPhoto"
        >
          上传{{ name }}
        </el-button>
        <el-button :disabled="imgList.length == 0" size="medium" @click="downloadPhoto">下载{{ name }}</el-button>
        <span style="font-size: 14px; color: #999; margin-left: 20px">* 点击图片可切换查看大图</span>
      </div>
      <div>
        <div v-if="isSelect">
          <el-checkbox v-model="checkAll" style="margin-right: 10px" @change="handleCheckAllChange">全选</el-checkbox>
          <el-button size="medium" type="primary" @click="del('批量')">批量删除</el-button>
          <el-button class="cancel" size="medium" type="primary" @click="isSelect = false">取消管理</el-button>
        </div>
        <el-button v-else size="medium" type="primary" @click="isSelect = true">管理照片</el-button>
      </div>
    </div>
    <div>
      <el-scrollbar v-if="imgList.length > 0" ref="elScroll" v-loading="loading" class="scrollBox" style="height: 500px">
        <el-checkbox-group v-model="checkedList" @change="handleCheckedCitiesChange">
          <div v-for="(n, i) in imgList" :key="n.id" class="imgs">
            <el-image lazy class="img" fit="cover" :src="showUrl(n.url, `x-oss-process=image/resize,m_fixed,h_${imageSize},w_${imageSize}`)" :preview-src-list="getImgList(i)">
              <span slot="error" class="el-image-error">
                <el-image class="img" :src="n.url" :preview-src-list="getImgList(i)" lazy>
                  <span slot="error" class="el-image-error">暂无图片</span>
                </el-image>
              </span>
            </el-image>
            <el-checkbox v-if="isSelect" :label="n.id"></el-checkbox>
            <el-button v-if="isSelect" size="mini" class="del" type="danger" icon="el-icon-delete" circle @click="del(n.id)"></el-button>
          </div>
        </el-checkbox-group>
      </el-scrollbar>
      <el-empty v-else :image-size="200"></el-empty>
    </div>
    <UploadPhotoPopups v-if="isUploadPhoto" type="1" popups-title="上传照片" :show-state="isUploadPhoto" :current-data="currentData" @hidePopups="isUploadPhoto = false" />
  </div>
</template>

<script>
import UploadPhotoPopups from '@/views/orderMgt/orderList/components/uploadPhoto'
import { showUrl } from '@/utils/yaImg'
import { getDetailPhotos, deleteBathUserPhoto } from '@/api/order'

export default {
  components: {
    UploadPhotoPopups,
  },
  props: {
    name: {
      type: String,
      default: '',
    },
    currentData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      checkAll: false,
      checkedList: [],
      imgList: [],
      isUploadPhoto: false,
      isSelect: false,
      form: {
        pageNumber: 1,
        pageSize: 9999,
        data: {},
      },
      total: 0,
      imageSize: 200,
      previews: [],
      loading: false,
    }
  },
  watch: {
    isSelect(val) {
      if (!val) {
        this.checkedList = []
        this.checkAll = false
      }
    },
  },
  mounted() {
    this.getDetailPhotos()
  },
  methods: {
    showUrl,
    getImgList(index) {
      return this.previews.slice(index).concat(this.previews.slice(0, index))
    },
    upPhoto() {
      this.isUploadPhoto = true
    },

    getDetailPhotos() {
      this.loading = true
      this.form.data.serviceItemId = this.currentData.serviceItemId
      this.form.data.baseProductType = this.currentData.baseProductType
      getDetailPhotos(this.form)
        .then((res) => {
          this.imgList = res.data.content
          this.total = Number(res.data.total)
          this.loading = false
          this.previews = this.imgList.map((item) => {
            return item.url
          })
        })
        .catch(() => {
          this.loading = false
        })
    },
    del(id) {
      let tip = '是否确定删除该照片'
      let ids = [id]
      if (id === '批量' && this.checkedList.length === 0) {
        this.$message({
          message: '请至少选择一张照片',
          type: 'warning',
        })
        return
      }
      if (id === '批量') {
        tip = `是否确定删除所选的${this.checkedList.length}照片`
        ids = this.checkedList
      }
      this.$confirm(tip, '确认提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        deleteBathUserPhoto({
          data: {
            ids: ids,
          },
        }).then(() => {
          this.$message({
            type: 'success',
            message: '删除成功!',
          })
          this.isSelect = false
          this.getDetailPhotos()
        })
      })
    },
    async downloadPhoto() {
      const data = []
      this.imgList.forEach((e, i) => {
        data.push({
          url: e.url.split('?')[0],
          name: i + 1,
          suffix: e.url.substring(e.url.lastIndexOf('.')),
        })
      })
      this.downLoading = true
      await this.$downloadPhoto(data)
      this.downLoading = false
    },
    handleCheckAllChange(val) {
      this.checkedList = val ? this.imgList.map((e) => e.id) : []
    },
    handleCheckedCitiesChange(value) {
      const checkedCount = value.length
      this.checkAll = checkedCount === this.imgList.length
    },
  },
}
</script>

<style lang="less" scoped>
@import '../../../styles/imgsList.less';
</style>
